import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

const selectKamereoBrandPageDomain = (state: ApplicationRootState) => state.kamereoBrandPage || initialState;

const selectKamereoBrandPage = () => createSelector(selectKamereoBrandPageDomain, (substate) => substate);

const selectLoading = () => createSelector(selectKamereoBrandPageDomain, (substate) => substate.loading);
const selectSections = () => createSelector(selectKamereoBrandPageDomain, (substate) => substate.sections);
const selectBrands = () => createSelector(selectKamereoBrandPageDomain, (substate) => substate.brands);
const selectNoteItemLoading = () => createSelector(selectKamereoBrandPageDomain, (substate) => substate.loadingNote);

export default selectKamereoBrandPage;
export { selectKamereoBrandPageDomain, selectLoading, selectSections, selectNoteItemLoading, selectBrands };
