import React, { memo } from 'react';
import { IBrandData } from '../types';
import styled from 'styles/styled-components';
import media from 'utils/mediaStyle';

import BrandItem from './BrandItem';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const ListBrand = styled.div`
  padding: 0 16px;
  display: flex;
  gap: 12px;
  flex-wrap: nowrap;
  overflow-x: auto;

  ${media.md`
    padding: 12px 0;
    gap: 24px;
  `};
`;

const Image = styled.img`
  width: 100%;
  aspect-ratio: 343/142;

  ${media.md`
    aspect-ratio: auto;
  `};
`;

interface IProps {
  brandData: IBrandData;
  listRef: React.RefObject<HTMLDivElement>[];
}
const KamereoBrandListSection = memo(({ brandData, listRef }: IProps) => {
  return (
    <Container>
      <Image src={brandData.mainBanner} loading="lazy" />
      <ListBrand>
        {brandData.brands.map((brand, index) => (
          <BrandItem brandRef={listRef[index]} key={brand.id} brand={brand} />
        ))}
      </ListBrand>
    </Container>
  );
});

export default KamereoBrandListSection;
