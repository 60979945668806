import { action, createAsyncAction } from 'typesafe-actions';
import ActionTypes from './constants';

export const kamereoBrands = createAsyncAction(
  ActionTypes.KAMAREO_BRANDS_REQUEST,
  ActionTypes.KAMAREO_BRANDS_SUCCESS,
  ActionTypes.KAMAREO_BRANDS_FAILURE,
)<void, {}, { [key: string]: { data: []; error: boolean } }>();

export const setKamereoBrandsFavirote = (data: any) => action(ActionTypes.KAMEREO_BRAND_FAVORITE, data);
export const kamereoBrandFavorite = createAsyncAction(
  ActionTypes.KAMEREO_BRAND_FAVORITE_REQUEST,
  ActionTypes.KAMEREO_BRAND_FAVORITE_SUCCESS,
  ActionTypes.KAMEREO_BRAND_FAVORITE_FAILURE,
)<void, {}, {}>();

export const setKamereoBrandProductNote = (data: any) => action(ActionTypes.KAMEREO_BRAND_SET_PRODUCT_NOTE, data);
export const kamereoBrandSetProductNote = createAsyncAction(
  ActionTypes.KAMEREO_BRAND_SET_PRODUCT_NOTE_REQUEST,
  ActionTypes.KAMEREO_BRAND_SET_PRODUCT_NOTE_SUCCESS,
  ActionTypes.KAMEREO_BRAND_SET_PRODUCT_NOTE_FAILURE,
)<void, {}, Error>();
