import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styles/styled-components';
import messages from '../messages';
import Kamereo from 'components/MainNav/Kamereo.svg';
import media from 'utils/mediaStyle';

import { messageIcon } from '../images';

const MessageWrapper = styled.div`
  margin: 0 16px;
  position: relative;
  margin-top: 20px;
  padding: 20px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #eaeaeb;
  background: #efffd6;

  ${media.lg`
    padding: 20px 120px;
    gap: 24px;
    margin: 0 ;
    margin-top:24px;
  `};
`;
const Message = styled.p`
  text-align: left;
  font-family: Inter;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 20px;

  ${media.lg`
   text-align: center;
   font-size: 20px;
   line-height: 28px;
  `};
`;

const FindMoreContainer = styled.p`
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;

  ${media.lg`
    text-align:center;
    font-size: 20px;
    line-height: 28px;
   `};
`;

const ClickHereContainer = styled.a`
  margin-left: 4px;
  color: #43a047;
  text-decoration-line: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
  cursor: pointer;

  &:hover {
    color: #43a047;
    font-weight: 500;
    text-decoration-line: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
  }
`;

const MessageIconContainer = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-25%, -50%);
  z-index: 50 !important;
  width: 35px;

  ${media.lg`
    width: auto;
   `};
`;

const KamereoLogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  ${media.lg`
  justify-content: center;
   `};
`;

const KamereoLogo = styled.img`
  width: 120px;
  ${media.lg`
    width: auto;
   `};
`;

const KamereoMessage = () => {

  const lang = window.localStorage.getItem('lang') || 'vi';

  return (
    <MessageWrapper>
      <Message>
        <FormattedMessage {...messages.kamereoTarget} />
      </Message>
      <KamereoLogoContainer>
        <KamereoLogo src={Kamereo} alt="KAMEREO - Đối tác cung cấp thực phẩm uy tín" />
      </KamereoLogoContainer>
      <FindMoreContainer>
        <FormattedMessage {...messages.findMore} />
        <ClickHereContainer target="_blank" href={lang === 'vi' ? "https://kamereo.vn/blog/vi/kamereo-lan-dau-ra-mat-loat-san-pham-bao-bi-xanh-tu-phan-huy-khang-dinh-quyet-tam-phat-trien-ben-vung/" : "https://kamereo.vn/blog/en/kamereo-launches-first-line-of-self-decomposing-green-packaging-products-affirming-commitment-to-sustainable-development/"}>
          <FormattedMessage {...messages.clickHere} />
        </ClickHereContainer>
      </FindMoreContainer>
      <MessageIconContainer src={messageIcon} />
    </MessageWrapper>
  );
};

export default KamereoMessage;
