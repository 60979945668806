import { ApplicationRootState } from 'types';
import { Product } from 'types/schema';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export enum BrandEnum {
  KAMEREO_VEGGIES = 'KAMEREO_VEGGIES',
  KAMEREO_ECO = 'KAMEREO_ECO',
  KAMEREO_DAILY = 'KAMEREO_DAILY',
}

export interface Brand {
  id: string;
  tag: BrandEnum;
  title: string;
  describe: string;
  previewImages: string[];
  additionalStyles?: string;
}

export interface IBrandData {
  mainBanner: string;
  brands: Brand[];
}

export interface ISectionData {
  data: Product[];
  banner: string;
  error: boolean;
}

export interface ISection {
  [k: string]: ISectionData;
}

export interface KamereoBrandPageState {
  readonly loading: boolean;
  readonly brands: IBrandData;
  readonly sections: ISection;
  readonly loadingNote: boolean;
}

type KamereoBrandPageActions = ActionType<typeof actions>;

export type RootState = ApplicationRootState;
export type ContainerState = KamereoBrandPageState;
export type ContainerActions = KamereoBrandPageActions;
