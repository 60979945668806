enum ActionTypes {
  KAMAREO_BRANDS_REQUEST = 'app/LandingPage/KamereoBrands/KAMEREO_BRANDS_REQUEST',
  KAMAREO_BRANDS_SUCCESS = 'app/LandingPage/KamereoBrands/KAMEREO_BRANDS_SUCCESS',
  KAMAREO_BRANDS_FAILURE = 'app/LandingPage/KamereoBrands/KAMEREO_BRANDS_FAILURE',
  KAMEREO_BRAND_FAVORITE = 'app/LandingPage/KamereoBrands/KAMEREO_BRAND_FAVORITE',
  KAMEREO_BRAND_FAVORITE_REQUEST = 'app/LandingPage/KamereoBrands/KAMEREO_BRAND_FAVORITE_REQUEST',
  KAMEREO_BRAND_FAVORITE_SUCCESS = 'app/LandingPage/KamereoBrands/KAMEREO_BRAND_FAVORITE_SUCCESS',
  KAMEREO_BRAND_FAVORITE_FAILURE = 'app/LandingPage/KamereoBrands/KAMEREO_BRAND_FAVORITE_FAILURE',
  KAMEREO_BRAND_SET_PRODUCT_NOTE = 'app/LandingPage/KamereoBrands/KAMEREO_BRAND_SET_PRODUCT_NOTE',
  KAMEREO_BRAND_SET_PRODUCT_NOTE_REQUEST = 'app/LandingPage/KamereoBrands/KAMEREO_BRAND_SET_PRODUCT_NOTE_REQUEST',
  KAMEREO_BRAND_SET_PRODUCT_NOTE_SUCCESS = 'app/LandingPage/KamereoBrands/KAMEREO_BRAND_SET_PRODUCT_NOTE_SUCCESS',
  KAMEREO_BRAND_SET_PRODUCT_NOTE_FAILURE = 'app/LandingPage/KamereoBrands/KAMEREO_BRAND_SET_PRODUCT_NOTE_FAILURE',
}

export default ActionTypes;
