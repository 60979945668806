/*
 * MarketPage Messages
 *
 * This contains all the text for the MarketPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.LandingPage.KamereoBrand';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Dòng sản phẩm thương hiệu Kamereo',
  },
  kamereoTarget: {
    id: `${scope}.kamereoTarget`,
    defaultMessage:
      '“KAMEREO hướng tới mô hình kinh doanh bền vững, thân thiện với môi trường. Các sản phẩm mới có khả năng tự phân hủy sinh học lên tới 60% trong vòng 2 năm, góp phần giảm thiểu rác thải nhựa gây ô nhiễm nghiêm trọng, điều mà hiện nay đang là 1 vấn nạn rất nhức nhối của xã hội.” ',
  },
  findMore: {
    id: `${scope}.findMore`,
    defaultMessage: 'Tìm hiểu thêm về tầm nhìn phát triển bền vững của Kamereo:',
  },
  clickHere: {
    id: `${scope}.clickHere`,
    defaultMessage: 'Tại đây',
  },

  viewAll: {
    id: `${scope}.viewAll`,
    defaultMessage: 'Xem tất cả sản phẩm',
  },
  exploreKamereo: {
    id: `${scope}.exploreKamereo`,
    defaultMessage: 'Khám Phá Các Sản Phẩm Thương Hiệu Kamereo - Giải Pháp Toàn Diện Cho Doanh Nghiệp',
  },
  exploreMessage: {
    id: `${scope}.exploreMessage`,
    defaultMessage: "Kamereo cung cấp một loạt các sản phẩm chất lượng cao, từ các món ăn sẵn tiện lợi, tươi ngon, đến các sản phẩm khăn giấy và bao bì thân thiện với môi trường đến. Tất cả sản phẩm đều được thiết kế để tối ưu hóa chi phí, tiết kiệm thời gian và mang lại giải pháp bền vững cho doanh nghiệp của bạn.",
  },
  exploreReadyToEatTitle: {
    id: `${scope}.exploreReadyToEatTitle`,
    defaultMessage: 'Sản Phẩm Ăn Liền & Rau Củ Tươi Kamereo',
  },
  exploreReadyToEatMessage: {
    id: `${scope}.exploreReadyToEatMessage`,
    defaultMessage: 'Các món ăn liền Kamereo giúp doanh nghiệp F&B tiết kiệm thời gian chế biến mà vẫn đảm bảo dinh dưỡng và hương vị:',
  },
  exploreEcoFriendlyTitle: {
    id: `${scope}.exploreEcoFriendlyTitle`,
    defaultMessage: 'Sản Phẩm Thân Thiện Môi Trường',
  },
  exploreEcoFriendlyMessage: {
    id: `${scope}.exploreEcoFriendlyMessage`,
    defaultMessage: 'Với xu hướng tiêu dùng xanh, Kamereo mang đến các sản phẩm sinh học phân hủy hoàn toàn, giúp doanh nghiệp bảo vệ môi trường và nâng cao uy tín, giúp giảm thiểu tác động đến môi trường mà vẫn đảm bảo sự tiện dụng, tiết kiệm.',
  },
  exploreConvinienceProductsTitle: {
    id: `${scope}.exploreConvinienceProductsTitle`,
    defaultMessage: 'Khăn Giấy, Nước Rửa Chén Cao Cấp Kamereo Siêu Tiện Lợi, Siêu Tiết Kiệm',
  },
  exploreConvinienceProductsMessage: {
    id: `${scope}.exploreConvinienceProductsMessage`,
    defaultMessage: 'Kamereo cung cấp các sản phẩm khăn giấy, nước rửa chén chuyên dụng chất lượng vượt trội, lý tưởng cho các doanh nghiệp yêu cầu vệ sinh cao:',
  },
  exploreCommit: {
    id: `${scope}.exploreCommit`,
    defaultMessage: '<i>Kamereo cam kết mang đến sự tiện ích, tiết kiệm chi phí và góp phần xây dựng thương hiệu xanh cho doanh nghiệp. <b>Liên hệ ngay với chúng tôi</b> để nhận báo giá và các ưu đãi cho đơn hàng lớn.</i>',
  },

  readyToEat: {
    id: `${scope}.readyToEat`,
    defaultMessage: 'Salad ăn liền & Sản phẩm tươi',
  },
  readyToEatSubTitle: {
    id: `${scope}.readyToEatSubTitle`,
    defaultMessage: 'Tiện lợi, dinh dưỡng đủ đầy',
  },
  ecoFriendly: {
    id: `${scope}.ecoFriendly`,
    defaultMessage: 'Thân thiện môi trường',
  },
  ecoFriendlySubTitle: {
    id: `${scope}.ecoFriendlySubTitle`,
    defaultMessage: 'Sống xanh, khỏe cùng Kamereo',
  },
  convinienceProducts: {
    id: `${scope}.convinienceProducts`,
    defaultMessage: 'Sản phẩm tiện dụng',
  },
  convinienceProductsSubTitle: {
    id: `${scope}.convinienceProductsSubTitle`,
    defaultMessage: 'Dành cho việc sử dụng hàng ngày',
  },
});
