import React, { FC, useCallback, useState } from 'react';
import { ISectionData } from '../types';
import styled from 'styles/styled-components';
import media from 'utils/mediaStyle';
import { Product, TrackingAddToCartAddFromType, TrackingProductImpressionType } from 'types/schema';
import MarketItem from 'components/MarketItem/MarketItem';
import { Dispatch } from 'redux';
import { IHomeFavoritePayload, IHomeProductNotePayload } from 'containers/HomePage/types';
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { AddedProduct, AddingProduct } from 'containers/MainLayout/types';
import { selectLoading, selectNoteItemLoading } from '../selectors';
import { selectAddedProducts, selectAddingProducts, selectUserPermissions } from 'containers/MainLayout/selectors';
import { connect } from 'react-redux';
import ModalNote from 'components/Checkout/ModalNote';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import { setKamereoBrandProductNote, setKamereoBrandsFavirote } from '../actions';

const Container = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 16px;
  ${media.md`
    margin-top:50px;
    padding: 0;
  `};
`;
const List = styled('div')`
  column-gap: 12px;
  padding: 1px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  ${media.lg`
  column-gap: 24px;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
  `};
`;

const Item = styled('div')`
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  ${media.md`
    width:100%;
    box-shadow: none;
  `};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const ButtonContainer = styled(Button)`
  width: 240px;
  height: 40px;
  border: 1px solid #cacbce;
  border-radius: 30px;
  color: #242527;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

const Image = styled.img`
  height: auto;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 343/178;

  ${media.md`
    aspect-ratio: auto;
  `};
`;

interface IOwnProps {
  sectionName: string;
  sectionData: ISectionData;
  showButtonViewAll?: boolean;
}

interface IStateProps {
  loading: boolean;
  addedProducts: AddedProduct;
  addingProducts: AddingProduct;
  permissions: string[];
  noteItemLoading: boolean;
}
interface IDispatchProps {
  onToggleFavorite: (data: IHomeFavoritePayload) => void;
  onSetProductNote: (data: IHomeProductNotePayload) => void;
  gotoSignIn: () => void;
  goToKamereoProducs: (data: string) => void;
}

type IProps = IStateProps & IDispatchProps & IOwnProps;

const BrandProductList: FC<IProps> = ({ showButtonViewAll = true, ...props }) => {
  // State
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [isOpenNoteModal, setIsOpenNoteModal] = useState<boolean>(false);

  // Props
  const loggedIn = !!localStorage.getItem('token');
  const {
    noteItemLoading,
    sectionData,
    sectionName,
    loading,
    addedProducts,
    addingProducts,
    permissions,
    onToggleFavorite,
    onSetProductNote,
    gotoSignIn,
    goToKamereoProducs,
  } = props;

  // Handlers
  const onClickOpenNoteModal = useCallback(
    (product: Product, index: number) => {
      if (loggedIn) {
        setSelectedProduct(product);
        setSelectedIndex(index);
        setIsOpenNoteModal(true);
      } else {
        gotoSignIn();
      }
    },
    [setSelectedProduct, setSelectedIndex, setIsOpenNoteModal, loggedIn],
  );

  const onClickCloseNoteModal = useCallback(() => {
    setSelectedProduct(undefined);
    setSelectedIndex(-1);
    setIsOpenNoteModal(false);
  }, [setSelectedProduct, setSelectedIndex, setIsOpenNoteModal]);

  const onSubmitAddingNote = useCallback(
    (note: string) => {
      if (selectedProduct) {
        onSetProductNote({
          index: selectedIndex,
          section: sectionName,
          note: note,
          id: selectedProduct.id,
        });
      }
      onClickCloseNoteModal();
    },
    [selectedProduct, sectionName, selectedIndex, onClickCloseNoteModal],
  );

  return (
    <Container>
      <Image src={sectionData?.banner || ''} loading="lazy" />
      <List>
        {sectionData?.data?.slice(0,5)?.map((product: Product, index: number) => (
          <Item key={product.id}>
            <MarketItem
              vertialOnly
              listName="Section Product List"
              loggedIn={loggedIn}
              gotoSignIn={gotoSignIn}
              product={product}
              loading={loading}
              favorite={product.isFavorite}
              note={product.note}
              added={addedProducts[product.id]}
              adding={addingProducts[product.id]}
              position={index}
              permissions={permissions}
              onToggleFavorite={() => onToggleFavorite({ product: product, index: index, section: sectionName })}
              onOpenNote={() => onClickOpenNoteModal(product, index)}
              onDeleteNote={() => {
                onSetProductNote({ index: index, section: sectionName, note: '', id: product.id });
                onClickCloseNoteModal();
              }}
              impressionProperties={{ type: TrackingProductImpressionType.CartProduct }}
              addToCartProperties={{ addFrom: TrackingAddToCartAddFromType.CartProduct }}
            />
          </Item>
        ))}
      </List>
      <ModalNote
        headerLabel={(selectedProduct && selectedProduct.name) || ''}
        isOpen={isOpenNoteModal}
        confirmLoading={noteItemLoading}
        onSubmit={onSubmitAddingNote}
        onClose={onClickCloseNoteModal}
        note={(selectedProduct && selectedProduct.note) || ''}
      />
      <ButtonWrapper>
        <ButtonContainer onClick={() => goToKamereoProducs(sectionName)}>
          <FormattedMessage {...messages.viewAll} />
        </ButtonContainer>
      </ButtonWrapper>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  loading: selectLoading(),
  addedProducts: selectAddedProducts(),
  addingProducts: selectAddingProducts(),
  permissions: selectUserPermissions(),
  noteItemLoading: selectNoteItemLoading(),
});
const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  onToggleFavorite: (data: IHomeFavoritePayload) => dispatch(setKamereoBrandsFavirote(data)),
  onSetProductNote: (data: IHomeProductNotePayload) => dispatch(setKamereoBrandProductNote(data)),
  gotoSignIn: () => dispatch(push('/signin')),
  goToKamereoProducs: (data: string) => dispatch(push(data)),
});

const withConnect = connect<IStateProps, IDispatchProps, IOwnProps>(mapStateToProps, mapDispatchToProps);

export default withConnect(BrandProductList);
