import { call, all, select, put, take, fork } from 'redux-saga/effects';

import { ProductSortField, QueryProductSearchArgs, SortDirection } from 'types/schema';
import { addFavorite, getProducts, removeFavorite, setProductNote } from 'utils/apollo';
import { selectBrands } from './selectors';
import { Brand, BrandEnum, IBrandData } from './types';
import { kamereoBrandFavorite, kamereoBrands, kamereoBrandSetProductNote } from './actions';
import {
  saladBanner,
  environmentFriendlyBanner,
  convenienceBanner,
  saladBannerEN,
  environmentFriendlyBannerEN,
  convenienceBannerEN,
  saladBannerMobile,
  saladBannerMobileEN,
  environmentFriendlyBannerMobile,
  environmentFriendlyBannerMobileEN,
  convenienceBannerMobile,
  convenienceBannerMobileEN,
} from './images';
import ActionTypes from './constants';
import { message } from 'antd';
import translations from 'translations';
import utilMessages from 'utils/messages';
import { updateNoteToCard } from 'containers/MainLayout/actions';
import { isMobile } from './reducer';

function* getSectionData(brand: Brand) {
  const lang = window.localStorage.getItem('lang') || 'vi';

  let banner = saladBanner;
  switch (brand.tag) {
    case BrandEnum.KAMEREO_VEGGIES:
      banner = lang === 'vi' ? isMobile ? saladBannerMobile : saladBanner : isMobile ? saladBannerMobileEN : saladBannerEN;
      break;

    case BrandEnum.KAMEREO_ECO:
      banner = lang === 'vi' ? isMobile ? environmentFriendlyBannerMobile : environmentFriendlyBanner : isMobile ? environmentFriendlyBannerMobileEN : environmentFriendlyBannerEN;
      break;

    case BrandEnum.KAMEREO_DAILY:
      banner = lang === 'vi' ? isMobile ? convenienceBannerMobile : convenienceBanner : isMobile ? convenienceBannerMobileEN : convenienceBannerEN;
      break;
  }

  const params: QueryProductSearchArgs = {
    sort: [{ field: ProductSortField.Name, order: SortDirection.Asc }],
    filter: {
      tags: [brand.tag],
    },
    pagination: { page: 0, size: 100 },
  };
  const response = yield call(getProducts, params);

  if (response.errors) {
    yield put(kamereoBrands.failure({ [brand.tag]: { data: [], error: true } }));
  } else {
    yield put(kamereoBrands.success({ [brand.tag]: { data: response.data, banner: banner, error: false } }));
  }
}

function* loadAllSectionData() {
  yield put(kamereoBrands.request());
  const currentBrands: IBrandData = yield select(selectBrands());
  yield all(
    currentBrands.brands.map((item: Brand) => {
      return call(getSectionData, item);
    }),
  );
}
function* kamereoBrandFavoriteFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.KAMEREO_BRAND_FAVORITE);
    yield put(kamereoBrandFavorite.request());

    const {
      product: { isFavorite, id },
      index,
      section,
    } = payload;

    const response = yield call(isFavorite ? removeFavorite : addFavorite, { id: id });
    if (response.errors) {
      yield put(kamereoBrandFavorite.failure({ index: index, section: section }));
    } else {
      yield put(kamereoBrandFavorite.success({ index: index, section: section, isFavorite: !isFavorite }));
    }
  }
}

function* kamereoBrandSetProductNoteFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.KAMEREO_BRAND_SET_PRODUCT_NOTE);
    yield put(kamereoBrandSetProductNote.request());

    const { id, note, index, section } = payload;
    const response = yield call(setProductNote, { input: { productId: id, note: note } });
    if (response.errors) {
      yield put(kamereoBrandSetProductNote.failure(response.errors));
      message.error(translations(utilMessages[payload.note ? 'addItemNoteFailed' : 'deleteItemNoteFailed']));
    } else {
      yield put(kamereoBrandSetProductNote.success({ note: note, index: index, section: section }));
      yield put(updateNoteToCard(response.product));
      message.success(translations(utilMessages[payload.note ? 'addItemNoteSuccess' : 'deleteItemNoteSuccess']));
    }
  }
}

export default function* saga() {
  yield call(loadAllSectionData);
  yield fork(kamereoBrandFavoriteFlow);
  yield fork(kamereoBrandSetProductNoteFlow);
}
