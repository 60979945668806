import React from 'react';
import { Card } from 'components/Card/Card';
import { Brand } from '../types';
import styled from 'styles/styled-components';
import media from 'utils/mediaStyle';
import { Viewport } from 'components/ViewportProvider';

const Container = styled(Card)`
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #eaeaeb;
  padding: 12px;
  cursor: pointer;
  min-width: 240px;
  overflow: hidden;
  &:hover {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    ${(props) => props.background && props.background}
  }
  ${media.md`
    width: 100%;
    padding: 16px;

  `};
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #242527;
  white-space: nowrap;

  ${media.md`
  font-size: 18px;
  `};
`;
const Describe = styled.p`
  font-size: 12px;
  line-height: 24px;
  color: #585a60;

  ${media.md`
    font-size: 16px;
    `};
`;
const ImageContainer = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 12px;
  align-items: center;
  
  ${media.lg`
    justify-content: space-between;
  `};

`;
const Image = styled.img`
  width: 80px;
  height: 80px;

  ${media.lg`
    width: 100px;
    height: 100px;
 `};
`;

interface IProps {
  brand: Brand;
  brandRef: React.RefObject<HTMLDivElement>;
}

const BrandItem = ({ brand, brandRef }: IProps) => {
  return (
    <Container
      background={brand.additionalStyles}
      onClick={() => {
        if (brandRef && brandRef.current) {
          const elementPosition = brandRef.current.getBoundingClientRect().top; // Lấy vị trí phần tử
          const offsetPosition = elementPosition + window.pageYOffset - 60; // Trừ đi chiều cao của navbar
          
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          }); 
        }
      }}
    >
      <Title>{brand.title}</Title>
      <Describe>{brand.describe}</Describe>
      <Viewport.Consumer>
        {({ width }) => (
          <ImageContainer>
            {brand?.previewImages?.slice(0, width < 1024 ? -1 : undefined)
              .map((image, index) => (
                <Image src={image} key={index} />
              ))}
          </ImageContainer>
       )}
      </Viewport.Consumer>
    </Container>
  );
};

export default BrandItem;
