import React, { FC, Fragment, useRef } from 'react';
import { Spin } from 'antd';
import Helmet from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import media from 'utils/mediaStyle';
import injectReducer from 'utils/injectReducer';
import reducer from './reducer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { ContainerState } from './types';
import selectKamereoBrandPage from './selectors';
import KamereoBrandListSection from './components/KamereoBrandListSection';
import BrandProductList from './components/BrandProductList';
import injectSaga from 'utils/injectSaga';
import renderWithWebDomain from 'utils/renderWithWebDomain';
import saga from './saga';
import styled from 'styles/styled-components';
import { subBanner, subBannerEN, subBannerMobile, subBannerMobileEN } from './images';
import KamereoMessage from './components/KamereoMessage';
import KamereoExplore from './components/KamereoExplore';
import { Viewport } from 'components/ViewportProvider';
import { selectLang } from 'containers/MainLayout/selectors';
import { LANGUAGES } from 'utils/constants';
import translations from 'translations';
import messages from './messages';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 75pt);
  margin-top: 99.66px;

  ${media.md`
    margin-top: 105.66px;
  `};
`;

const SubBanner = styled.div`
  margin-top: 20px;
  padding: 0 16px;
  img {
    width: 100%;
  }

  ${media.md`
   margin-top: 46px;
    padding: 0 ;

`};
`;

export interface IStateProps {
  kamereoPage: ContainerState;
  localLang: string;
}

type Props = IStateProps & RouteComponentProps;
const KamereoBrandPage: FC<Props> = (props) => {
  const {
    kamereoPage: { brands, sections, loading },
    localLang,
  } = props;

  const alternateLang = localLang === LANGUAGES.Vi ? LANGUAGES.En : LANGUAGES.Vi;
  const validUrl = `thuong-hieu-kamereo${localLang === 'vi' ? '' : '?lang=en'}`;
  const alternateUrl = `thuong-hieu-kamereo${alternateLang === 'vi' ? '' : '?lang=en'}`;

  const saladRef = useRef<HTMLDivElement>(null);
  const environmentRef = useRef<HTMLDivElement>(null);
  const convinienceRef = useRef<HTMLDivElement>(null);

  return (
    <Fragment>
      <Helmet>
        <title>{translations(messages.title)}</title>
        <meta property="og:url" content={(renderWithWebDomain(process.env.APP_ENV), validUrl)} />
        <link rel="canonical" href={(renderWithWebDomain(process.env.APP_ENV), validUrl)} />
        <link rel="alternate" href={(renderWithWebDomain(process.env.APP_ENV), validUrl)} />
        <link rel="alternate" hrefLang={localLang} href={renderWithWebDomain(process.env.APP_ENV, validUrl)} />
        <link rel="alternate" hrefLang={alternateLang} href={renderWithWebDomain(process.env.APP_ENV, alternateUrl)} />
        <link rel="alternate" hrefLang="x-default" href={renderWithWebDomain(process.env.APP_ENV, validUrl)} />
        <meta name="description" content={`${translations(messages.kamereoTarget)}`} />
        <meta property="og:title" content="Dòng sản phẩm thương hiệu Kamereo" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Spin spinning={loading}>
        <MainContainer>
          <KamereoBrandListSection listRef={[saladRef, environmentRef, convinienceRef]} brandData={brands} />

          {/* Salad */}
          <div ref={saladRef}>
            <BrandProductList sectionData={sections['KAMEREO_VEGGIES']} sectionName="KAMEREO_VEGGIES" />
          </div>

          {/* Sub Banner */}
          <Viewport.Consumer>
            {({ width }) => (
              <SubBanner>
                {' '}
                <img
                  src={
                    width > 1024
                      ? localLang === 'vi'
                        ? subBanner
                        : subBannerEN
                      : localLang === 'vi'
                        ? subBannerMobile
                        : subBannerMobileEN
                  }
                  loading="lazy"
                />
              </SubBanner>
            )}
          </Viewport.Consumer>

          {/* Enviroment Friendly */}
          <div ref={environmentRef}>
            <BrandProductList sectionData={sections['KAMEREO_ECO']} sectionName="KAMEREO_ECO" />
          </div>

          {/* Kamereo Message */}
          <KamereoMessage />

          {/* Convinience product */}
          <div ref={convinienceRef}>
            <BrandProductList sectionData={sections['KAMEREO_DAILY']} sectionName="KAMEREO_DAILY" />
          </div>

          {/* Kamereo Explore */}
          <KamereoExplore kamereoProducts={sections} />
        </MainContainer>
      </Spin>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  kamereoPage: selectKamereoBrandPage(),
  localLang: selectLang(),
});

const withConnect = connect(mapStateToProps);

const withReducer = injectReducer({
  key: 'kamereoBrandPage',
  reducer: reducer,
});

const withSaga = injectSaga({ key: 'kamereoBrandPage', saga: saga });

export default compose(withConnect, withReducer, withSaga)(KamereoBrandPage);
