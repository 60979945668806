import { BrandEnum, ContainerActions, ContainerState } from './types';
import ActionTypes from './constants';
import messages from './messages';
const lang = window.localStorage.getItem('lang') || 'vi';
import {
  mainBanner,
  salad1,
  salad2,
  salad3,
  environmentFriendly1,
  environmentFriendly2,
  environmentFriendly3,
  convenienceProduct1,
  convenienceProduct2,
  convenienceProduct3,
  mainBannerEN,
  mainBannerMobile,
  mainBannerMobileEN,
} from './images';
import translations from 'translations';

export const isMobile = window.document.body.clientWidth < 768;

export const initialState: ContainerState = {
  loading: true,
  sections: {},
  loadingNote: false,
  brands: {
    mainBanner:
      lang === 'vi' ? (isMobile ? mainBannerMobile : mainBanner) : isMobile ? mainBannerMobileEN : mainBannerEN,
    brands: [
      {
        id: '1',
        tag: BrandEnum.KAMEREO_VEGGIES,
        title: translations(messages.readyToEat),
        describe: translations(messages.readyToEatSubTitle),
        previewImages: [salad1, salad2, salad3],
        additionalStyles: `background-color: #C6EDFB;
        transition-timing-function: ease-out;
        transition: 0.3s;`,
      },
      {
        id: '2',
        tag: BrandEnum.KAMEREO_ECO,
        title: translations(messages.ecoFriendly),
        describe: translations(messages.ecoFriendlySubTitle),
        previewImages: [environmentFriendly1, environmentFriendly2, environmentFriendly3],
        additionalStyles: `background-color: #D3F1A7;
        transition-timing-function: ease-out;
        transition: 0.3s;`,
      },
      {
        id: '3',
        tag: BrandEnum.KAMEREO_DAILY,
        title: translations(messages.convinienceProducts),
        describe: translations(messages.convinienceProductsSubTitle),
        previewImages: [convenienceProduct1, convenienceProduct2, convenienceProduct3],
        additionalStyles: `background-color: #FDD0EC;
        transition-timing-function: ease-out;
        transition: 0.3s;`,
      },
    ],
  },
};

function kamereoBrandReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.KAMAREO_BRANDS_REQUEST:
      return { ...state, loading: true };

    case ActionTypes.KAMAREO_BRANDS_SUCCESS:
      return { ...state, loading: false, sections: { ...state.sections, ...payload } };

    case ActionTypes.KAMAREO_BRANDS_FAILURE: {
      const keys = Object.keys(payload);
      const sections = { ...state.sections };
      keys.forEach((key) => {
        if (!sections[key]) {
          sections[key] = payload[key];
        }
      });
      return { ...state, loading: false, sections: sections };
    }
    case ActionTypes.KAMEREO_BRAND_SET_PRODUCT_NOTE_REQUEST: {
      return { ...state, loadingNote: true };
    }

    case ActionTypes.KAMEREO_BRAND_SET_PRODUCT_NOTE_SUCCESS: {
      const { note, index, section } = payload;
      const products = [...state.sections[section].data];
      products[index] = { ...products[index], note: note };
      return {
        ...state,
        sections: { ...state.sections, [section]: { data: products, error: false } },
        loadingNote: false,
      };
    }

    case ActionTypes.KAMEREO_BRAND_SET_PRODUCT_NOTE_FAILURE: {
      return { ...state, loadingNote: false };
    }

    case ActionTypes.KAMEREO_BRAND_FAVORITE_SUCCESS: {
      const { index, section, isFavorite } = payload;
      const products = [...state.sections[section].data];
      products[index] = { ...products[index], isFavorite: isFavorite };
      return {
        ...state,
        sections: { ...state.sections, [section]: { data: products, error: false } },
      };
    }

    default:
      return state;
  }
}

export default kamereoBrandReducer;
