import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styles/styled-components';
import messages from '../messages';
import Kamereo from 'components/MainNav/Kamereo.svg';
import translations from 'translations';
import generateProductSlug from 'utils/generateProductSlug';
import { KamereoLogo } from 'components/MainNav/TopNavComponents';
import { ISection } from '../types';
import media from 'utils/mediaStyle';

const ExploreWrapper = styled.div`
  margin: 20px 16px;
  display: flex;
  padding: 20px 20px;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
  border-radius: 12px;
  border: 1px solid #eaeaeb;
  background: #ffffff;

  ${media.md`
    margin: 96px auto;
    padding: 20px 72px;
    gap: 24px;
    align-items: center;
  `};
`;
const Title = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

   ${media.md`
    text-align: center;
    font-size: 24px;
    line-height: 32px;
  `};
`;

const ListKamereoProductContainer = styled.div`
  margin-top: 16px;
  width: 100%;

  ${media.md`
    margin-top: 0;
  `};
`

const SubTitle = styled.div`
  width: 100%;
  text-align: left;
  color: #242527;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  ${media.md`
    font-size: 16px;
  `};
`;

const Message = styled.div`
  width: 100%;
  color: #242527;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  ${media.md`
    font-size: 16px;
  `};
`;

const ProductName = styled.li`
  margin-left: 5px;
  color: #242527;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: fit-content;
  cursor: pointer;
  &:hover {
    color: #43A047;
  }

  ${media.md`
    margin-left: 10px;
    font-size: 16px;
  `};
`

const Spacer = styled.div`
  width: 100%;
  height: 6px;
`

interface Props {
  kamereoProducts: ISection;
}

const KamereoExplore = ({ kamereoProducts }: Props) => {

  const lang = localStorage.getItem('lang') || 'vi';

  return (
    <ExploreWrapper>
      <Title>
        <FormattedMessage {...messages.exploreKamereo} />
      </Title>
      <KamereoLogo src={Kamereo} alt="KAMEREO - Đối tác cung cấp thực phẩm uy tín" />

      <ListKamereoProductContainer>
        <Message>
          <FormattedMessage {...messages.exploreMessage} />
        </Message>
        <br />
        <SubTitle>
          <FormattedMessage {...messages.exploreReadyToEatTitle} />
        </SubTitle>
        <br />
        <Message>
          <FormattedMessage {...messages.exploreReadyToEatMessage} />
        </Message>
        <br />

        {kamereoProducts['KAMEREO_VEGGIES']?.data.map((product, index) => (
          <Fragment key={product.id}>
            <a href={`/products/${generateProductSlug(product.name, product?.supplierInternalCode, lang)}`} target="_blank">
              <ProductName>
                {product.name}
              </ProductName>
            </a>
            {index < kamereoProducts['KAMEREO_VEGGIES']?.data.length - 1 && <Spacer />}
          </Fragment>
        ))}

        <br />
        <SubTitle>
          <FormattedMessage {...messages.exploreEcoFriendlyTitle} />
        </SubTitle>
        <br />
        <Message>
          <FormattedMessage {...messages.exploreEcoFriendlyMessage} />
        </Message>
        <br />

        {kamereoProducts['KAMEREO_ECO']?.data.map((product, index) => (
          <Fragment key={product.id}>
            <a href={`/products/${generateProductSlug(product.name, product?.supplierInternalCode, lang)}`} target="_blank">
              <ProductName>
                {product.name}
              </ProductName>
            </a>
            {index < kamereoProducts['KAMEREO_ECO']?.data.length - 1 && <Spacer />}
          </Fragment>
        ))}

        <br />
        <SubTitle>
          <FormattedMessage {...messages.exploreConvinienceProductsTitle} />
        </SubTitle>
        <br />

        <Message>
          <FormattedMessage {...messages.exploreConvinienceProductsMessage} />
        </Message>
        <br />

        {kamereoProducts['KAMEREO_DAILY']?.data.map((product, index) => (
          <Fragment key={product.id}>
            <a href={`/products/${generateProductSlug(product.name, product?.supplierInternalCode, lang)}`} target="_blank">
              <ProductName>
                {product.name}
              </ProductName>
            </a>
            {index < kamereoProducts['KAMEREO_DAILY']?.data.length - 1 && <Spacer />}
          </Fragment>
        ))}
        <br />
        <Message
          dangerouslySetInnerHTML={{
            __html: translations(messages.exploreCommit),
          }}
        />
      </ListKamereoProductContainer>
    </ExploreWrapper>
  );
};

export default KamereoExplore;
